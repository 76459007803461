<template>
  <div class="rootDiv">
    <div class="retrieve">
      <div class="searchDiv">
        <div class="flex flex-jb mb-20">
          <div></div>
          <div class="flex flex-ac">
            <div class="fs-16 pointer mr-40" @click="submitForm(configData.goodSortOff)">
              <i class="el-icon-sort mr-10 fs-18"></i>{{configData.goodSortOff==1?'订单排序':'默认排序'}}
            </div>
            <div class="fs-16 fc-6b9eff pointer" @click="addList">
              <i class="el-icon-circle-plus-outline mr-10 fs-18"></i>新增
            </div>
          </div>
        </div>
        <div class="flex flex-ac">
          <div class="mr-30 flex flex-ac">
            <span class="fs-16 flex1 mr-20">系列名称：</span>
            <el-input class="w-250" v-model="retrieveForm.seriesName" clearable></el-input>
          </div>
          <div class="mr-30 flex flex-ac">
            <span class="fs-16 flex1 mr-20">类型：</span>
            <el-select class="w-250" v-model="retrieveForm.goodSeriesType" clearable placeholder="请选择">
              <el-option v-for="item in goodSeriesTypes" :key="item.value" :label="item.key" :value="item.value">
              </el-option> s
            </el-select>
          </div>
          <div class="mr-30 flex flex-ac">
            <span class="fs-16 flex1 mr-20">系列状态：</span>
            <el-select class="w-250" v-model="retrieveForm.seriesPattern" clearable>
              <el-option v-for="item in sericeTypeOptions" :key="item.label" :label="item.key"
                :value="item.value"></el-option>
            </el-select>
          </div>
          <span class="fs-16 flex1 mr-20">是否上架：</span>
          <el-select class="w-250" v-model="retrieveForm.shelves" clearable placeholder="请选择">
            <el-option v-for="item in shelves" :key="item.value" :label="item.key" :value="item.value">
            </el-option>
          </el-select>
          <div label="">
            <el-button type="primary" @click="submitSearch">查询</el-button>
          </div>
        </div>
      </div>
    </div>
    <TableHeightFull class="tableDiv pall-30">
      <EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%" :border="false"
        @row-click="headerRowClick" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
        <template v-slot:img="scope">
          <img :src="scope.row.coverImg" alt="" class="smallImg" v-if="scope.row.coverImg">
        </template>
      </EleTable>
      <PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableData" />
    </TableHeightFull>
    <!-- 一番赏详情 -->
    <shop-details ref="shop-details" @refresh="getTableData"></shop-details>
    <!-- 修改记录 -->
    <el-dialog title="修改记录" :visible.sync="dialogUseVisible">
      <div class="dialog-content">
        <EleTable ref="recordRef" slot="record" :tableData="recordData" :columns="recordColumns" height="100%"
          v-loading="recLoading">
        </EleTable>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogUseVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogUseVisible = false">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 复制弹层 -->
    <el-dialog title="复制" :visible.sync="dialogFormVisible" style="width: 50%;margin-left: 25%;">
      <el-form label-width="100px">
        <el-form-item label="系列名称" label-width="100px">
          <el-input v-model="copyDialog.seriesName" placeholder="木有可复制的数据啦" style=" width: 250px;"></el-input>
        </el-form-item>
        <el-form-item label="系列状态" label-width="100px">
          <el-select class="w-250" v-model="copyDialog.seriesPattern" placeholder="木有可复制的数据啦" clearable>
            <el-option v-for="item in sericeTypeOptions" :key="item.key" :label="item.key"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="copySubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import indexMixin from "@/utils/indexMixin"
import { mapState } from 'vuex'
export default {
  name: 'drawsGood',
  mixins: [indexMixin],
  components: {
    "EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
    "TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
    "PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
    "CountDown": (resolve) => require(["@/components/CountDown"], resolve),
    "shop-details": (resolve) => require(["@/views/modules/goods/shopDetails"], resolve),
  },
  computed: {
    ...mapState('menu', {
      'goodSeriesTypes': state => state.dictList ? state.dictList.good_series_type : [],  //优惠劵类型
      'shelves': state => state.dictList ? state.dictList.shelves : [],  //是否上下架
    })
  },
  data () {
    return {
      copyDialog: {
        id: '',
        seriesName: '',
        seriesPattern: '',
      },

      retrieveForm: {
        seriesName: '',
        seriesPattern: '',
        goodSeriesType: '',
        shelves: ''
      },
      sericeTypeOptions: [{
        value: 1,
        key: '排队'
      }, {
        value: 0,
        key: '竞技'
      }],
      configData: '',
      tabLoading: false,
      recLoading: false,
      columns: [
        { type: 'index', label: '序号', width: '60', align: 'center', fixed: 'left', index: this.indexMethod },
        { columnType: 'custom', label: '封面图', prop: 'img', align: 'left', width: '80' },
        { label: '系列名称', prop: 'seriesName', align: 'left', 'show-overflow-tooltip': true },
        { label: '价格', prop: 'soldPrice', align: 'left', 'show-overflow-tooltip': true },
        {
          columnType: 'fun', label: '系列分类', prop: 'goodSeriesType', align: 'left', 'show-overflow-tooltip': true, formatData: (e) => {
            let ele = this.goodSeriesTypes.find(ele => ele.value == e)
            return ele ? ele.key : e
          }
        },
        { label: '创建时间', prop: 'createDate', align: 'left', 'show-overflow-tooltip': true },
        { label: '发货日期', prop: 'deliverDate', align: 'left', 'show-overflow-tooltip': true },
        {
          label: '系列状态', prop: 'seriesPattern', align: 'left', 'show-overflow-tooltip': true, formatData: (e) => {
            let ele = this.sericeTypeOptions.find(ele => ele.value == e)
            return ele ? ele.key : e
          }
        },
        {
          columnType: 'fun', label: '是否上架', prop: 'shelves', align: 'left', 'show-overflow-tooltip': true, formatData: (e) => {
            let ele = this.shelves.find(ele => ele.value == e)
            return ele ? ele.key : e
          }
        },
        {
          columnType: 'button', label: '操作', align: 'right', fixed: 'right', width: '200',
          buttons: [
            { type: 'text', text: '同步', fn: this.synchronousList, hidden: (e) => { return e.parent != 0 } },
            { type: 'text', text: '复制', fn: this.copyList },
            { type: 'text', text: '记录', fn: this.recordList },
            { type: 'text', text: '编辑', fn: this.editList },
            { type: 'text', text: '删除', fn: this.deleteList }
          ],
        },
      ],
      tableData: [],
      //弹框
      dialogFormVisible: false,
      dialogUseVisible: false,
      recordData: [],//修改记录
      recordColumns: [
        { type: 'index', label: '序号', width: '60', align: 'center', index: this.indexMethod },
        { label: '修改日期', prop: 'createDate', align: 'left', 'show-overflow-tooltip': true },
        { label: '修改用户', prop: 'username', align: 'left', 'show-overflow-tooltip': true },
        { label: '系列名称', prop: 'seriesName', align: 'left', 'show-overflow-tooltip': true },
        { label: '发货日期', prop: 'deliverDate', align: 'left', 'show-overflow-tooltip': true },
        { label: '系列状态', prop: 'seriseType', align: 'left', 'show-overflow-tooltip': true },
        { label: '价格', prop: 'soldPrice', align: 'left', 'show-overflow-tooltip': true },
      ],
    }
  },
  mounted () {
    this.getTableData()
    this.findSystemConfig()
  },
  methods: {
    //查询
    submitSearch () {
      this.pageInfo.pageNum = 1
      this.getTableData()
    },
    //新增列表
    addList () {
      this.$refs['shop-details'].init()
    },
    //导出
    exportExcel () { },
    //获取表格数据
    getTableData () {
      this.tabLoading = true
      this.$http.get('/goodSeries/finGoodSeriesAll', {
        params: {
          currentPage: this.pageInfo.pageNum,
          pageSize: this.pageInfo.pageSize,
          seriesGoodsType: 1,
          ...this.retrieveForm,
        }
      }).then(({ data: result }) => {
        console.log(result.data)
        this.tabLoading = false
        this.tableData = result.data.list
        this.pageInfo.total = result.data.total
        // this.retrieveForm.seriseType=result.data
      }).catch(err => {
        this.tabLoading = false
      })
    },
    //复制弹框
    copyList (row) {
      // console.log(row)
      this.dialogFormVisible = true
      this.copyDialog = JSON.parse(JSON.stringify(row))

    },
    // 确认复制
    copySubmit (row) {
      this.$http.post('/goodSeries/copySeriesById', {
        ...this.copyDialog
      }).then(({ data: result }) => {
        this.$message({
          message: '复制成功',
          type: 'success'
        })
        this.dialogFormVisible = false
      }).catch((err) => {
        this.dialogFormVisible = false
      })
    },
    //记录
    recordList (row) {
      this.dialogUseVisible = true
      this.recLoading = true
      this.$http.get('/seriesEdit/findSeriesEdit', {
        params: {
          boxType: 5,
          seriesId: row.id,
        }
      }).then(({ data: result }) => {
        this.recordData = result.data
        this.recLoading = false
      }).catch((err) => {
        this.recLoading = false
      })
    },
    //查询基础配置
    findSystemConfig () {
      this.$http.get('/systemConfig/findSystemConfig').then((result) => {
        let configList = result.data.data
        if (configList.length > 0) {
          this.configData = configList[0]
        }
      }).catch((err) => { })
    },
    //保存 默认次数
    submitForm (sortOff) {
      this.$confirm('您确认调整排序方式？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        let configData = this.configData
        configData.goodSortOff = sortOff ? 0 : 1
        this.$http.post('/systemConfig/editSystemConfig', configData).then((result) => {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          this.findSystemConfig()
        }).catch((err) => { })
      }).catch(_ => { })
    },
    //编辑
    editList (row) {
      this.$refs['shop-details'].init(row.id)
    },
    //同步
    synchronousList (data) {
      this.$confirm('您确认同步？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        this.$http.post('/goodSeries/syncSeriesData', { id: data.id }).then(res => {
          this.$message({
            message: '同步成功',
            type: 'success'
          })
        })
      }).catch(_ => { })
    },
    //删除
    deleteList (data) {
      this.$confirm('您确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        this.$http.post('/goodSeries/removeGoodSeries', { id: data.id }).then(res => {
          this.getTableData()
          this.$message({
            message: '删除成功',
            type: 'success'
          })
        })
      }).catch(_ => { })
    },
    //跳转详情
    headerRowClick (row) {
      // console.log(row)
    },
  },
}
</script>

<style lang="less" scoped>
.dialog-content {
  max-height: 250px;
}
</style>